'use client';

import { motion } from 'framer-motion';

// Pulsating Circle
export const PulsatingCircle = (): JSX.Element => <div className="flex items-center justify-center w-20 h-20" data-sentry-component="PulsatingCircle" data-sentry-source-file="loading-components.tsx">
    <motion.div className="w-16 h-16 bg-blue-400 rounded-full" animate={{
    scale: [1, 1.2, 1],
    opacity: [1, 0.5, 1]
  }} transition={{
    duration: 1.5,
    repeat: Infinity,
    ease: 'easeInOut'
  }} data-sentry-element="unknown" data-sentry-source-file="loading-components.tsx" />
  </div>;

// Rotating Square
export const RotatingSquare = (): JSX.Element => <div className="flex items-center justify-center w-20 h-20" data-sentry-component="RotatingSquare" data-sentry-source-file="loading-components.tsx">
    <motion.div className="w-16 h-16 bg-purple-400" animate={{
    rotate: 360,
    borderRadius: ['0%', '0%', '50%', '50%', '0%']
  }} transition={{
    duration: 2,
    repeat: Infinity,
    ease: 'easeInOut'
  }} data-sentry-element="unknown" data-sentry-source-file="loading-components.tsx" />
  </div>;

// Bouncing Dots
export const BouncingDots = (): JSX.Element => <div className="flex items-center justify-center space-x-2 w-20 h-20" data-sentry-component="BouncingDots" data-sentry-source-file="loading-components.tsx">
    {[0, 1, 2].map(index => <motion.div key={index} className="w-3 h-3 bg-slate-400 rounded-full" animate={{
    y: ['0%', '-100%', '0%']
  }} transition={{
    duration: 0.6,
    repeat: Infinity,
    ease: 'easeInOut',
    delay: index * 0.2
  }} />)}
  </div>;

// Spinning Circles
export const SpinningCircles = (): JSX.Element => <div className="flex items-center justify-center w-20 h-20" data-sentry-component="SpinningCircles" data-sentry-source-file="loading-components.tsx">
    <motion.div className="w-16 h-16 border-4 border-amber-400 rounded-full" animate={{
    rotate: 360,
    borderColor: ['#fbbf24', '#34d399', '#60a5fa', '#fbbf24']
  }} transition={{
    duration: 2,
    repeat: Infinity,
    ease: 'linear'
  }} data-sentry-element="unknown" data-sentry-source-file="loading-components.tsx">
      <motion.div className="w-8 h-8 border-4 border-rose-400 rounded-full" animate={{
      rotate: -360,
      borderColor: ['#fb7185', '#818cf8', '#34d399', '#fb7185']
    }} transition={{
      duration: 2,
      repeat: Infinity,
      ease: 'linear'
    }} data-sentry-element="unknown" data-sentry-source-file="loading-components.tsx" />
    </motion.div>
  </div>;

// Fading Squares
export const FadingSquares = (): JSX.Element => <div className="flex items-center justify-center w-20 h-20" data-sentry-component="FadingSquares" data-sentry-source-file="loading-components.tsx">
    <div className="grid grid-cols-2 gap-2">
      {[0, 1, 2, 3].map(index => <motion.div key={index} className="w-6 h-6 bg-indigo-400" animate={{
      opacity: [0.5, 1, 0.5]
    }} transition={{
      duration: 1.5,
      repeat: Infinity,
      ease: 'easeInOut',
      delay: index * 0.2
    }} />)}
    </div>
  </div>;

// Expanding Rings
export const ExpandingRings = (): JSX.Element => <div className="flex items-center justify-center w-20 h-20" data-sentry-component="ExpandingRings" data-sentry-source-file="loading-components.tsx">
    {[0, 1, 2].map(index => <motion.div key={index} className="absolute w-16 h-16 border-2 border-emerald-400 rounded-full" animate={{
    scale: [1, 1.5, 1],
    opacity: [1, 0, 1]
  }} transition={{
    duration: 2,
    repeat: Infinity,
    ease: 'easeInOut',
    delay: index * 0.4
  }} />)}
  </div>;