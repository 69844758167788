'use client';

import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BouncingDots } from '@/components/Loading/loading-components';
import { WebsiteCard } from '@/components/WebsiteCard/website-card';
import { TabType, useWebsiteList } from './useWebsiteList';
import { WebsiteListSkeleton } from './website-list-skeleton';
import { WebsitesTabs } from './website-tabs';
interface WebsiteListProps {
  className?: string;
}
const tabs: readonly TabType[] = ['Featured', 'Latest', 'Mine'] as const;
export const WebsiteList: React.FC<WebsiteListProps> = ({
  className
}) => {
  const {
    websites,
    error,
    hasMore,
    loadNextPage,
    updateQueryParams,
    isLoading
  } = useWebsiteList();
  const handleStateChange = useCallback(({
    activeTab,
    searchQuery
  }: {
    activeTab: TabType;
    searchQuery: string;
  }) => {
    updateQueryParams({
      tab: activeTab,
      search: searchQuery
    });
  }, [updateQueryParams]);
  return <div className={`space-y-6 ${className}`} data-sentry-component="WebsiteList" data-sentry-source-file="website-list.tsx">
      <WebsitesTabs tabs={tabs} initialActiveTab="Featured" initialSearchQuery="" onStateChange={handleStateChange} data-sentry-element="WebsitesTabs" data-sentry-source-file="website-list.tsx" />
      {error && <div className="text-center py-4 text-red-500">{error}</div>}
      {isLoading && websites.length === 0 ? <WebsiteListSkeleton /> : <InfiniteScroll dataLength={websites.length} next={loadNextPage} hasMore={hasMore} loader={<div className="flex flex-col items-center mt-16">
              <BouncingDots />
              <p className="mt-2 text-sm text-gray-600">loading...</p>
            </div>} endMessage={<p className="text-center py-4 text-lg font-semibold">
              🎉 Wow! You've reached the end of the internet! 🌐
            </p>}>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 max-w-screen-2xl mx-auto">
            {websites.map(website => <div key={website.id} className="w-full">
                <WebsiteCard website={website} />
              </div>)}
          </div>
        </InfiniteScroll>}
    </div>;
};